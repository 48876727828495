import {useContext, useEffect, useState} from "react";
import {Line} from "react-chartjs-2";
import {
    ArcElement,
    Chart as ChartJS,
    Colors,
    Legend,
    LinearScale, LineElement,
    PointElement, TimeScale, Title,
    Tooltip
} from "chart.js";
import {AxiosContext} from "../Router";
import 'chartjs-adapter-luxon';

const entityUrlSegment = {
    "repository": "repositories",
    "team": "teams",
    "user": "users",
}

ChartJS.register(ArcElement, Title, LinearScale, TimeScale, PointElement, LineElement, Colors, Tooltip, Legend);

export default function PullRequestsPerWeekCompareChart({ entityType }) {
    const [data, setData] = useState([])
    const { axiosClientRef } = useContext(AxiosContext)

    useEffect(() => {
        axiosClientRef.current.get(`/${entityUrlSegment[entityType]}/pull_requests_per_week_compare.json`).then(res => {
            setData(res.data);
        });
    }, [axiosClientRef, entityType]);

    useEffect(() => {
        console.log(chartData);
    }, [data]);

    const chartData = {
        datasets:
            data.map(userRow => {
                return {
                    label: userRow.login,
                    data: userRow.data.map(row => {
                        return {
                            x: row.week_start,
                            y: row.pull_requests
                        }
                    }),
                    fill: false,

                }
            })

    }

    const chartOptions = {
        adapters: {
            date: {
                locale: 'en-GB'
            }
        },
        plugins: {
            legend: {
                position: 'bottom',
            },
            colors: {
                enabled: true
            }
        },
        responsive: true,
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'week',
                    displayFormats: {
                        week: 'd MMM'
                    },
                    tooltipFormat: 'd MMM'
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: '# of Pull Requests',
                },
                stacked: false
            },
        }
    }

    return (<Line data={chartData} options={chartOptions} />);
}
