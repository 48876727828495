import axios from "axios";

export function createAxiosClient(state) {
    const client = axios.create({
        baseURL: `${process.env.REACT_APP_API_URL}`,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        }
    })

    client.interceptors.request.use(
        function (config) {
            if (state.currentOrganization.id) {
                config.headers['X-Organization-Id'] = state.currentOrganization.id
            }
            return config;
        },
        function (error) {
            return Promise.reject(error)
        }
    )

    client.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            if (error.response.status === 401) {
                console.log("Bad request")
                window.location.href = "/login"
            }

            return Promise.reject(error)
        }
    );

    client.defaults.xsrfCookieName = 'CSRF-TOKEN';
    client.defaults.xsrfHeaderName = 'X-CSRF-Token';
    client.defaults.withCredentials = true;

    return client
}
