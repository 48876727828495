
import {createContext, Fragment, useContext} from 'react'
import { Menu, Transition } from '@headlessui/react'
import {Navigate, NavLink, Outlet} from "react-router-dom";
import {RocketLaunchIcon, UserCircleIcon} from "@heroicons/react/20/solid";



const navigation = [
    { name: 'Dashboard', href: "/"},
    { name: 'Repositories', href: "repositories" },
    { name: 'Pull Requests', href: "pull-requests/matrix" },
    { name: 'Teams', href: "teams" },
    { name: 'Users', href: "users" },
    { name: 'Workflows', href: "workflows" },
]

export const AuthnContext = createContext({});

function UserIcon() {
    const { state } = useContext(AuthnContext);

    if (state.session == null || state.session.user.avatar_url == null) {
        return (
            <UserCircleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
        )
    }

    return (
        <img className="h-9 w-9 rounded-full border border-1 border-slate-700" src={state.session.user.avatar_url} />
    )
}

function App() {
    const { state, dispatch } = useContext(AuthnContext);

    if (state.session == null) {
        console.log("App: No session found, redirecting to login page")
        return (
            <Navigate to="/login" />
        )
    }

    const userNavigation = [
        { name: 'Sign out', href: "logout" },
    ]

    const updateCurrentOrganization = (organizationId) => {
        dispatch({type: 'UPDATE_CURRENT_ORGANIZATION', payload: { organizationId: organizationId }})
    }

    return (
            <div className="min-h-full">
                <nav className="bg-slate-800">
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="flex h-16 items-center justify-between">
                            <div className="flex items-center">
                                <div className="flex-shrink-0 rounded-full text-white">
                                    <RocketLaunchIcon className="h-7 w-7" aria-hidden="true" />
                                </div>
                                <div className="hidden md:block">
                                    <div className="ml-10 flex items-baseline space-x-4">
                                        {navigation.map((item) => (
                                            <NavLink to={item.href}
                                                     key={item.name}
                                                     className={({isActive}) =>
                                                         (isActive ? 'bg-slate-600 text-white' : 'text-white hover:bg-slate-700 hover:text-white') + ' rounded-md px-3 py-2 text-sm font-medium'
                                                     }
                                            >
                                                {item.name}
                                            </NavLink>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="ml-4 flex items-center md:ml-6">
                                    <Menu as="div" className="relative ml-3">
                                        <Menu.Button className="flex max-w-xs items-center rounded-full text-white text-sm hover:bg-slate-700">
                                            <span className="px-3 py-2 font-medium">{state.currentOrganization.login}</span>
                                        </Menu.Button>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                {state.session.user.organizations && state.session.user.organizations.map((org) => (
                                                    <Menu.Item key={org.id}>
                                                        {({ active }) => (
                                                            <button
                                                                className={({isActive, isPending}) => (
                                                                    (isActive ? 'bg-slate-100' : '') + ' block px-4 py-2 text-sm text-slate-700'
                                                                )}
                                                                onClick={() => updateCurrentOrganization(org.id)}
                                                            >
                                                                {org.login}
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                ))}
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                    <Menu as="div" className="relative ml-3">
                                        <div>
                                            <Menu.Button className="flex max-w-xs items-center rounded-full text-white text-sm hover:bg-slate-700">
                                                <span className="rounded-md px-3 py-2 text-sm text-white font-medium">{state.session.user.login}</span>
                                                <UserIcon />
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                {userNavigation.map((item) => (
                                                    <Menu.Item key={item.name}>
                                                        {({ active }) => (
                                                            <NavLink to={item.href}
                                                                className={({isActive, isPending}) => (
                                                                    (isActive ? 'bg-slate-100' : '') + ' block px-4 py-2 text-sm text-slate-700'
                                                                )}
                                                            >
                                                                {item.name}
                                                            </NavLink>
                                                        )}
                                                    </Menu.Item>
                                                ))}
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                <Outlet />
            </div>
    );
}

export default App;
