import {useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {
    ArcElement,
    CategoryScale,
    Chart as ChartJS,
    Colors,
    Legend,
    LinearScale, LineElement,
    PointElement, Title,
    Tooltip
} from "chart.js";
import PullRequestsByStatePerWeekChart from "../widgets/PullRequestsByStatePerWeekChart";
import ReviewersPerPullRequestPerWeekChart from "../widgets/ReviewersPerPullRequestPerWeekChart";
import PullRequestLifespanPerWeekChart from "../widgets/PullRequestLifespanPerWeekChart";
import MostActiveRepositoriesList from "../widgets/MostActiveRepositoriesList";
import {AxiosContext} from "../Router";
import FilesChangedPerPullRequestPerWeekChart from "../widgets/FilesChangedPerPullRequestPerWeekChart";
import LinesChangedPerPullRequestPerWeekChart from "../widgets/LinesChangedPerPullRequestPerWeekChart";
import PullRequestTimeToFirstReviewPerWeekChart from "../widgets/PullRequestTimeToFirstReviewPerWeekChart";

ChartJS.register(ArcElement, Title, LinearScale, CategoryScale, PointElement, LineElement, Colors, Tooltip, Legend);

export default function Show() {
    const { userId } = useParams()
    const [data, setData] = useState({})
    const [loaded, setLoaded] = useState(false)
    const { axiosClientRef } = useContext(AxiosContext)

    useEffect(() => {
        axiosClientRef.current.get(`/users/${userId}.json`).then(res => {
            setData(res.data);
            setLoaded(true);
        });
    }, [axiosClientRef, userId])

    if(!loaded) {
        return <></>
    }

    return (
        <>
            <header className="bg-white shadow">
                <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold tracking-tight text-gray-900">{data.login}</h1>
                </div>
            </header>
            <main>
                <div className="container mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 bg-white my-5 shadow">
                    <div className="grid grid-cols-2 gap-4">
                        <div className="max-h-96 bg-white shadow rounded-lg p-4">
                            <h2 className="text-xl font-bold text-gray-900 mb-3">Pull requests</h2>
                            <PullRequestsByStatePerWeekChart entityType="user" entityId={userId} />
                        </div>
                        <div className="max-h-96 bg-white shadow rounded-lg p-4">
                            <h2 className="text-xl font-bold text-gray-900 mb-3">Reviewers per PR</h2>
                            <ReviewersPerPullRequestPerWeekChart entityType="user" entityId={userId} />
                        </div>
                        <div className="max-h-96 bg-white shadow rounded-lg p-4">
                            <h2 className="text-xl font-bold text-gray-900 mb-3">PR lifespan</h2>
                            <PullRequestLifespanPerWeekChart entityType="user" entityId={userId} />
                        </div>
                        <div className="flex flex-col max-h-96 bg-white shadow rounded-lg p-4">
                            <h2 className="text-xl font-bold text-gray-900 mb-3">Time to first review</h2>
                            <PullRequestTimeToFirstReviewPerWeekChart entityType="user" entityId={userId} />
                            <div className="text-xs pt-3 text-right">Last 12 weeks</div>
                        </div>
                        <div className="max-h-96 bg-white shadow rounded-lg p-4">
                            <h2 className="text-xl font-bold text-gray-900 mb-3">Files changed per PR</h2>
                            <FilesChangedPerPullRequestPerWeekChart entityType="user" entityId={userId} />
                        </div>
                        <div className="max-h-96 bg-white shadow rounded-lg p-4">
                            <h2 className="text-xl font-bold text-gray-900 mb-3">Lines changed per PR</h2>
                            <LinesChangedPerPullRequestPerWeekChart entityType="user" entityId={userId} />
                        </div>
                        <div className="flex flex-col max-h-96 bg-white shadow rounded-lg p-4">
                            <h2 className="text-xl font-bold text-gray-900 mb-3">Most active repositories</h2>
                            <MostActiveRepositoriesList entityType="user" entityId={userId} />
                            <div className="text-xs pt-3 text-right">Last 12 weeks</div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
