import App, {AuthnContext} from "./App";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import React, {createContext, useReducer, useRef} from "react";
import Dashboard from "./Dashboard";
import TeamMatrix from "./TeamMatrix";
import Login from "./authentication/Login";
import { default as RepositoryIndex} from "./repositories/Index";
import { default as RepositoryShow } from "./repositories/Show";
import { default as TeamIndex } from "./teams/Index";
import { default as TeamShow } from "./teams/Show";
import { default as UserIndex} from "./users/Index";
import { default as UserCompare} from "./users/Compare";
import { default as UserShow } from "./users/Show";
import { default as WorkflowIndex } from "./workflows/Index";
import { default as WorkflowShow } from "./workflows/Show";
import {initialState, reducer} from "./reducer";
import {createAxiosClient} from "./axiosClient";
import Logout from "./authentication/Logout";
import Callback from "./authentication/Callback";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                index: true,
                element: <Dashboard />
            },
            {
                path: "repositories",
                element: <RepositoryIndex />
            },
            {
                path: "repositories/:repositoryId",
                element: <RepositoryShow />
            },
            {
                path: "teams",
                element: <TeamIndex />
            },
            {
                path: "teams/:teamId",
                element: <TeamShow />
            },
            {
                path: "users",
                element: <UserIndex />
            },
            {
                path: "users/compare",
                element: <UserCompare />
            },
            {
                path: "users/:userId",
                element: <UserShow />
            },
            {
                path: "pull-requests/matrix",
                element: <TeamMatrix />
            },
            {
                path: "workflows",
                element: <WorkflowIndex />
            },
            {
                path: "workflows/:workflowId",
                element: <WorkflowShow />
            }
        ]
    },
    {
        path: "/login",
        element: <Login />
    },
    {
        path: "/logout",
        element: <Logout />
    },
    {
        path: "/callback",
        element: <Callback />
    }
]);

export const AxiosContext = createContext();

export default function Router() {
    const [state, dispatch] = useReducer(reducer, initialState);
    const axiosClient = createAxiosClient(state);
    const axiosClientRef = useRef(axiosClient);

    return (
        <AuthnContext.Provider value={{ state, dispatch }}>
            <AxiosContext.Provider value={{ axiosClientRef }}>
                <RouterProvider router={router} />
            </AxiosContext.Provider>
        </AuthnContext.Provider>
    )
}
