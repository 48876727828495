import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AxiosContext} from "../Router";

const entityUrlSegment = {
    "team": "teams",
    "user": "users",
}
export default function MostActiveRepositoriesList({ entityType, entityId }) {
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const { axiosClientRef } = useContext(AxiosContext)

    const goToRepository = (repositoryId) => {
        navigate(`/repositories/${repositoryId}`);
    };

    useEffect(() => {
        axiosClientRef.current.get(`/${entityUrlSegment[entityType]}/${entityId}/most_active_repositories.json`).then((res) => {
            setData(res.data);
        });
    }, [axiosClientRef, entityType, entityId]);

    return (
        <div className="flex-grow overflow-y-scroll">
            {data.map(record => (
                <div
                    key={record.repository_id}
                    className="cursor-pointer border p-3 rounded max-w-full my-3"
                    onClick={() => goToRepository(record.repository_id)}
                >
                    <div className="font-bold">{record.repository_name}</div>
                    <div className="text-sm text-gray-500">{record.num_pull_requests} pull requests</div>
                </div>
            ))}
        </div>
    );
}
