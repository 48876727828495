import {useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {Doughnut} from "react-chartjs-2";
import {
    ArcElement,
    CategoryScale,
    Chart as ChartJS,
    Colors,
    Legend,
    LinearScale, LineElement,
    PointElement, Title,
    Tooltip
} from "chart.js";
import PullRequestsByStatePerWeekChart from "../widgets/PullRequestsByStatePerWeekChart";
import ReviewersPerPullRequestPerWeekChart from "../widgets/ReviewersPerPullRequestPerWeekChart";
import PullRequestLifespanPerWeekChart from "../widgets/PullRequestLifespanPerWeekChart";
import MostActiveAuthorsList from "./widgets/MostActiveAuthorsList";
import {AxiosContext} from "../Router";
import FilesChangedPerPullRequestPerWeekChart from "../widgets/FilesChangedPerPullRequestPerWeekChart";
import LinesChangedPerPullRequestPerWeekChart from "../widgets/LinesChangedPerPullRequestPerWeekChart";
import PullRequestTimeToFirstReviewPerWeekChart from "../widgets/PullRequestTimeToFirstReviewPerWeekChart";

ChartJS.register(ArcElement, Title, LinearScale, CategoryScale, PointElement, LineElement, Colors, Tooltip, Legend);

// Return a donut chart from data of the following shape:
// [
//   {
//     "name": "Ruby",
//     "bytes": 0
//   },
//   ...
// ]
function LanguagesChart({ data }) {
    // Major colour present in the logos of the following languages:
    // Ruby, JavaScript, TypeScript, HTML, CSS, Python, Go, Java, C++, C, C#,
    // Shell, deployment.Dockerfile, Procfile, YAML, Vue, SCSS, Haml, PHP, Swift, Kotlin,
    const languageColors = {
        "Ruby": "rgb(224, 64, 64)",
        "JavaScript": "rgb(255, 229, 100)",
        "TypeScript": "rgb(0, 121, 191)",
        "HTML": "rgb(255, 90, 95)",
        "CSS": "rgb(0, 121, 191)",
        "Python": "rgb(255, 229, 100)",
        "Go": "rgb(43,164,231)",
        "Java": "rgb(78,124,224)",
        "C++": "rgb(0, 121, 191)",
        "C": "rgb(0, 121, 191)",
        "C#": "rgb(54,194,103)",
        "Shell": "rgb(47,47,47)",
        "Dockerfile": "rgb(0, 121, 191)",
        "Procfile": "rgb(123,64,224)",
        "YAML": "rgb(0, 121, 191)",
        "Vue": "rgb(52,213,146)",
        "SCSS": "rgb(255, 229, 100)",
        "Haml": "rgb(222,206,130)",
        "PHP": "rgb(118,115,225)",
        "Swift": "rgb(206,130,42)",
        "Kotlin": "rgb(0, 121, 191)",
    }

    const chartData = {
        labels: data.map(row => row.name),
        datasets: [
            {
                label: "Languages",
                data: data.map(row => row.bytes),
                // Colors for common languages
                backgroundColor: data.map(row => languageColors[row.name] || "rgb(201, 203, 207)"),
            }
        ]
    }

    const chartOptions = {
        plugins: {
            legend: {
                position: 'bottom',
            }
        },
        responsive: false,
        maintainAspectRatio: false,
    }

    return (<Doughnut data={chartData} options={chartOptions} />);
}

export default function Show() {
    const { repositoryId } = useParams()
    const [data, setData] = useState({})
    const [loaded, setLoaded] = useState(false)
    const { axiosClientRef } = useContext(AxiosContext)

    useEffect(() => {
        axiosClientRef.current.get(`/repositories/${repositoryId}.json`).then(res => {
            setData(res.data);
            setLoaded(true);
        });
    }, [axiosClientRef, repositoryId])

    if(!loaded) {
        return <></>
    }

    return (
        <>
            <header className="bg-white shadow">
                <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold tracking-tight text-gray-900">{data.name}</h1>
                </div>
            </header>
            <main>
                <div className="container mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 bg-white my-5 shadow">
                    <div className="grid grid-cols-2 gap-4">
                        <div className="max-h-96 bg-white shadow rounded-lg p-4">
                            <h2 className="text-xl font-bold text-gray-900 mb-3">Pull requests</h2>
                            <PullRequestsByStatePerWeekChart entityType="repository" entityId={repositoryId} />
                        </div>
                        <div className="max-h-96 bg-white shadow rounded-lg p-4">
                            <h2 className="text-xl font-bold text-gray-900 mb-3">Reviewers per PR</h2>
                            <ReviewersPerPullRequestPerWeekChart entityType="repository" entityId={repositoryId} />
                        </div>
                        <div className="max-h-96 bg-white shadow rounded-lg p-4">
                            <h2 className="text-xl font-bold text-gray-900 mb-3">PR lifespan</h2>
                            <PullRequestLifespanPerWeekChart entityType="repository" entityId={repositoryId} />
                        </div>
                        <div className="flex flex-col max-h-96 bg-white shadow rounded-lg p-4">
                            <h2 className="text-xl font-bold text-gray-900 mb-3">Time to first review</h2>
                            <PullRequestTimeToFirstReviewPerWeekChart entityType="repository" entityId={repositoryId} />
                            <div className="text-xs pt-3 text-right">Last 12 weeks</div>
                        </div>
                        <div className="max-h-96 bg-white shadow rounded-lg p-4">
                            <h2 className="text-xl font-bold text-gray-900 mb-3">Files changed per PR</h2>
                            <FilesChangedPerPullRequestPerWeekChart entityType="repository" entityId={repositoryId} />
                        </div>
                        <div className="max-h-96 bg-white shadow rounded-lg p-4">
                            <h2 className="text-xl font-bold text-gray-900 mb-3">Lines changed per PR</h2>
                            <LinesChangedPerPullRequestPerWeekChart entityType="repository" entityId={repositoryId} />
                        </div>
                        <div className="flex flex-col max-h-96 bg-white shadow rounded-lg p-4">
                            <h2 className="flex text-xl font-bold text-gray-900 mb-3">Most active authors</h2>
                            <MostActiveAuthorsList repositoryId={repositoryId} />
                            <div className="text-xs pt-3 text-right">Last 12 weeks</div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
