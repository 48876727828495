export default function Dashboard() {
    return (
        <>
            <header className="bg-white shadow">
                <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold tracking-tight text-gray-900">Dashboard</h1>
                </div>
            </header>
            <main>
                <div className="container mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 bg-white my-5 shadow">
                    <p>Welcome to Code Compass - a tool for providing insight into your organization's Github usage.</p>
                    <br />
                    <p>Use the menu options at the top of this page to view insights through different lenses.</p>
                </div>
            </main>
        </>
    )
}
