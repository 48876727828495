import {useContext, useEffect, useState} from "react";
import {AxiosContext} from "./Router";

export default function TeamMatrix() {
    const [dataLoaded, setDataLoaded] = useState(false);
    const [data, setData] = useState({});
    const [filteredAuthor, setFilteredAuthor] = useState('');
    const [filteredReviewer, setFilteredReviewer] = useState('');
    const { axiosClientRef } = useContext(AxiosContext)


    const setAuthor = (event) => {
        setFilteredAuthor(event.target.value);
    }

    const setReviewer = (event) => {
        setFilteredReviewer(event.target.value);
    }

    const statsWith = (data, author, otherPerson) => {
        const matchA = data.review_records.find(record => record.author === author && record.reviewer === otherPerson)
        const matchB = data.review_request_records.find(record => record.author === author && record.requested_reviewer === otherPerson)

        return (
            <>{matchA ? matchA.num_reviews : 0 } | {matchB ? matchB.num_review_requests : 0}</>
        )
    }

    useEffect(() => {
        axiosClientRef.current.get(`/pull_requests/team_matrix`)
            .then(res => {
                const usersA = res.data.review_records.map(record => [record.author, record.reviewer]).flat()
                const usersB = res.data.review_request_records.map(record => [record.author, record.requested_reviewer]).flat()
                const allUsers = [...new Set(usersA.concat(usersB))]

                allUsers.sort((a, b) => {
                    return a.toLowerCase().localeCompare(b.toLowerCase());
                })

                setData({allUsers: allUsers, review_records: res.data.review_records, review_request_records: res.data.review_request_records});
                setDataLoaded(true);
            })
    }, [axiosClientRef, setDataLoaded, setData])

    return (
        <>
            <header className="bg-white shadow">
                <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold tracking-tight text-gray-900">Matrix</h1>
                </div>
            </header>
            <main>
                <div className="container mx-auto max-w-full py-6 sm:px-6 lg:px-8 bg-white my-5 shadow">
                    <div className="border-l-4 border-blue-700 bg-blue-50 text-blue-700 rounded-md p-6 text-sm">
                        <p>
                            This matrix shows for each author (left) and reviewer (top) pair how many pull requests of the author were reviewed by the reviewer and how many review requests remain open (i.e. how many of the author's PRs are still waiting for review by the requested reviewer). Uses the last 6 months of data.
                        </p>
                    </div>
                    <div className="flex flex-row mt-8 my-6 space-x-4">
                        <div>
                            <label htmlFor="author" className="block text-sm font-medium leading-6 text-gray-900">Author</label>
                            <input type="text" id="author" onChange={setAuthor} className="border-0 rounded-md p-1.5 shadow-sm ring-1 ring-inset ring-gray-300" />
                        </div>
                        <div>
                            <label htmlFor="reviewer" className="block text-sm font-medium leading-6 text-gray-900">Reviewer</label>
                            <input type="text" id="reviewer" onChange={setReviewer} className="border-0 rounded-md p-1.5 shadow-sm ring-1 ring-inset ring-gray-300" />
                        </div>
                    </div>
                    {dataLoaded ? (
                        <div className="overflow-x-scroll">
                        <table className="text-xs">
                            <tbody>
                                <tr>
                                    <th></th>
                                    {data.allUsers.filter(user => filteredReviewer === '' || filteredReviewer === undefined || user.toLowerCase().includes(filteredReviewer.toLowerCase())).map(user =>
                                        <th className="whitespace-nowrap p-1" key={user}>{user}</th>
                                    )}
                                </tr>
                                {data.allUsers.map(author =>
                                filteredAuthor === '' || filteredAuthor === undefined || author.toLowerCase().includes(filteredAuthor.toLowerCase()) ? (
                                    <tr key={author} className="hover:bg-sky-50">
                                        <th className="text-right whitespace-nowrap p-1">{author}</th>
                                        {data.allUsers.filter(user => filteredReviewer === '' || filteredReviewer === undefined || user.toLowerCase().includes(filteredReviewer.toLowerCase())).map(otherPerson =>
                                            <td key={(author + '-' + otherPerson)} className="text-center whitespace-nowrap p-1">
                                                { author === otherPerson ? "-" : statsWith(data, author, otherPerson) }
                                            </td>
                                        )}
                                    </tr>
                                ) : "")}
                            </tbody>
                        </table>
                        </div>
                    ) : "Loading data..."}
                </div>
            </main>
        </>
    )
}
