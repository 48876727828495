import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AxiosContext} from "../Router";


export default function Index() {
    const [data, setData] = useState([])
    const navigate = useNavigate()
    const { axiosClientRef } = useContext(AxiosContext)

    const goToUser = (userId) => {
        navigate(`/users/${userId}`);
    }

    useEffect(() => {
        axiosClientRef.current.get(`/users.json`).then(res => {
            setData(res.data);
        });
    }, [axiosClientRef])

    return (
        <>
            <header className="bg-white shadow">
                <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold tracking-tight text-gray-900">Users</h1>
                </div>
            </header>
            <main>
                <div className="container mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 bg-white my-5 shadow">
                    <div className="cursor-pointer border p-6 rounded my-5" onClick={() => navigate('/users/compare')}>
                        <div className="font-bold">Compare</div>
                    </div>
                    {data.map(user =>
                        <div key={user.id} className="cursor-pointer border p-6 rounded max-w-full my-5" onClick={() => goToUser(user.id)}>
                            <div className="font-bold">{user.login}</div>
                            <div className="text-sm">{user.name}</div>
                        </div>
                    )}
                </div>
            </main>
        </>
    );
}
