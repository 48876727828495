import {useContext, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {AuthnContext} from "../App";
import {AxiosContext} from "../Router";

export default function Logout() {
    const { dispatch } = useContext(AuthnContext);
    const { axiosClientRef } = useContext(AxiosContext)
    const navigate = useNavigate();


    let ignore = false;

    useEffect(() => {
        if (!ignore) {
            axiosClientRef.current.delete('/sessions/logout.json').then(() => {
                // TODO: Verify the response indicates success

                dispatch({
                    type: "LOGOUT",
                })

                navigate("/");
            });

            ignore = true;
        }
    }, [dispatch, navigate])

    return (<></>)
}
