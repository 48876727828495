import {useContext, useEffect, useState} from "react";
import {Line} from "react-chartjs-2";
import {
    ArcElement,
    Chart as ChartJS,
    Colors,
    Legend,
    LinearScale, LineElement,
    PointElement, TimeScale, Title,
    Tooltip
} from "chart.js";
import {AxiosContext} from "../Router";
import 'chartjs-adapter-luxon';

ChartJS.register(ArcElement, Title, LinearScale, TimeScale, PointElement, LineElement, Colors, Tooltip, Legend);

const entityUrlSegment = {
    "repository": "repositories",
    "organization": "organizations",
    "team": "teams",
    "user": "users",
}

// Return a chart from data of the following shape:
// [
//   {
//     "week_start": "2021-01-04",
//     "num_reviewers_p50": 1.0
//   },
//   ...
// ]
export default function ReviewersPerPullRequestPerWeekChart({ entityType, entityId }) {
    const [data, setData] = useState([])
    const { axiosClientRef } = useContext(AxiosContext)

    useEffect(() => {
        axiosClientRef.current.get(`/${entityUrlSegment[entityType]}/${entityId}/reviews_per_pull_request_per_week.json`).then(res => {
            setData(res.data);
        });
    }, [axiosClientRef, entityType, entityId])


    const chartData = {
        datasets: [
            {
                label: "Median",
                data: data.map(row => {
                    return {
                        x: row.week_start,
                        y: row.num_reviewers_p50
                    }
                }),
                fill: false,
                backgroundColor: "rgb(99, 167, 255)",
                borderColor: "rgba(99, 167, 255, 0.4)",
            }
        ]
    }

    const chartOptions = {
        adapters: {
            date: {
                locale: 'en-GB'
            }
        },
        plugins: {
            legend: {
                position: 'bottom',
            }
        },
        responsive: true,
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'week',
                    displayFormats: {
                        week: 'd MMM'
                    },
                    tooltipFormat: 'd MMM'
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: '# of Reviewers',
                },
            }
        }
    }

    return (<Line data={chartData} options={chartOptions} />);
}
