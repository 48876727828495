const fetchSessionFromStorage = () => {
    const session = JSON.parse(localStorage.getItem("session"));

    if (session) {
        return session;
    }

    return null;
};

export const initialState = {
    session: fetchSessionFromStorage(),
    currentOrganization: fetchSessionFromStorage()?.user?.organizations[0] || null
};

export const reducer = (state, action) => {
    switch (action.type) {
        case "LOGIN": {
            localStorage.setItem("session", JSON.stringify(action.payload))

            return {
                ...state,
                session: action.payload,
                currentOrganization: action.payload.user.organizations[0]
            };
        }
        case "LOGOUT": {
            localStorage.clear()

            return {
                ...state,
                session: null
            };
        }
        case "UPDATE_CURRENT_ORGANIZATION":
            return {
                ...state,
                currentOrganization: action.payload.organization,
            };
        default:
            return state;
    }
};
