import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AxiosContext} from "../Router";


export default function Index() {
    const [data, setData] = useState([])
    const navigate = useNavigate()
    const { axiosClientRef } = useContext(AxiosContext)

    const goToWorkflow = (workflowId) => {
        navigate(`/workflows/${workflowId}`);
    }

    useEffect(() => {
        axiosClientRef.current.get(`/workflows.json`).then(res => {
            setData(res.data);
        });
    }, [axiosClientRef])

    return (
        <>
            <header className="bg-white shadow">
                <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold tracking-tight text-gray-900">Workflows</h1>
                </div>
            </header>
            <main>
                <div className="container mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 bg-white my-5 shadow">
                    <table className="table w-full">
                        <thead>
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Repository</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map(workflow =>
                                <tr key={workflow.id} className="cursor-pointer border p-6 rounded max-w-full my-5" onClick={() => goToWorkflow(workflow.id)}>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-medium text-gray-900">
                                            {workflow.name}
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm text-gray-500">
                                            {workflow.repository.name}
                                        </div>
                                    </td>
                                </tr>
                                )}
                        </tbody>
                    </table>
                </div>
            </main>
        </>
    );
}
