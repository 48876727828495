import {useContext, useEffect, useState} from "react";
import {AuthnContext} from "../App";
import {Navigate} from "react-router-dom";
import {AxiosContext} from "../Router";

export default function Callback() {
    const { dispatch } = useContext(AuthnContext);
    const { axiosClientRef } = useContext(AxiosContext)
    const [ loginComplete, setLoginComplete ] = useState(false);

    let ignore = false;

    useEffect(() => {

        if (!ignore) {
            // After requesting Github access, Github redirects back to your app with a code parameter
            const url = window.location.href;
            const hasCode = url.includes("?code=");

            // If Github API returns the code parameter
            if (hasCode) {
                const newUrl = url.split("?code=");

                const requestData = {
                    code: newUrl[1]
                };


                axiosClientRef.current.post('/sessions.json', requestData).then(res => {
                    // TODO: Verify the response indicates success

                    dispatch({
                        type: "LOGIN",
                        payload: {
                            user: res.data.user
                        }
                    })

                    setLoginComplete(true);
                });
            }
        }

        ignore = true;
    }, []);

    if(loginComplete) {
        return (<Navigate to="/" />)
    }

    return (<></>)
}
