import {useContext, useEffect, useState} from "react";
import {AxiosContext} from "../Router";
import {ChangeEvent} from "react";
import PullRequestsPerWeekCompareChart from "../widgets/PullRequestsPerWeekCompareChart";


export default function Compare() {
    const [activeWidget, setActiveWidget] = useState(<>Hi</>)
    const { axiosClientRef } = useContext(AxiosContext)

    const loadComponent = (event: ChangeEvent<HTMLSelectElement>) => {
        switch(event.target.value) {
            case "pullRequests":
                setActiveWidget(<PullRequestsPerWeekCompareChart entityType="user" />)
                break
            case "linesChanged":
                setActiveWidget(<>Lines changed</>)
                break
        }
    }

    return (
        <>
            <header className="bg-white shadow">
                <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold tracking-tight text-gray-900">Users</h1>
                </div>
            </header>
            <main>
                <div className="container mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 bg-white my-5 shadow">
                    <select onChange={loadComponent}>
                        <option value="linesChanged">Lines changed</option>
                        <option value="pullRequests">Pull Requests</option>
                    </select>
                    <br />
                    {activeWidget}
                </div>
            </main>
        </>
    );
}
