import {useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {
    ArcElement,
    CategoryScale,
    Chart as ChartJS,
    Colors,
    Legend,
    LinearScale, LineElement,
    PointElement, Title,
    Tooltip
} from "chart.js";
import {AxiosContext} from "../Router";
import PullRequestsByStatePerWeekChart from "../widgets/PullRequestsByStatePerWeekChart";
import WorkflowRunsByWorkflowPerWeekChart from "../widgets/WorkflowRunsByWorkflowPerWeekChart";

ChartJS.register(ArcElement, Title, LinearScale, CategoryScale, PointElement, LineElement, Colors, Tooltip, Legend);

export default function Show() {
    const { workflowId } = useParams()
    const [data, setData] = useState({})
    const [loaded, setLoaded] = useState(false)
    const { axiosClientRef } = useContext(AxiosContext)

    useEffect(() => {
        axiosClientRef.current.get(`/workflows/${workflowId}.json`).then(res => {
            setData(res.data);
            setLoaded(true);
        });
    }, [axiosClientRef, workflowId])

    if(!loaded) {
        return <></>
    }

    return (
        <>
            <header className="bg-white shadow">
                <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold tracking-tight text-gray-900">{data.name}</h1>
                </div>
            </header>
            <main>
                <div className="container mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 bg-white my-5 shadow">
                    <div className="grid grid-cols-2 gap-4">
                        <div className="max-h-96 bg-white shadow rounded-lg p-4">
                            <h2 className="text-xl font-bold text-gray-900 mb-3">Workflow runs</h2>
                            <WorkflowRunsByWorkflowPerWeekChart workflowId={workflowId}/>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
