import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AxiosContext} from "../../Router";

export default function MostActiveAuthorsList({ repositoryId }) {
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const { axiosClientRef } = useContext(AxiosContext)

    const goToUser = (userId) => {
        navigate(`/users/${userId}`);
    };

    useEffect(() => {
        axiosClientRef.current.get(`/repositories/${repositoryId}/most_active_authors.json`).then((res) => {
            setData(res.data);
        });
    }, [axiosClientRef,repositoryId]);

    return (
        <div className="flex-grow overflow-y-scroll">
            {data.map(record => (
                <div
                    key={record.user_id}
                    className="cursor-pointer border p-3 rounded max-w-full my-3"
                    onClick={() => goToUser(record.user_id)}
                >
                    <div className="font-bold">{record.user_login}</div>
                    <div className="text-sm text-gray-500">{record.num_pull_requests} pull requests</div>
                </div>
            ))}
        </div>
    );
}
