import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AxiosContext} from "../Router";


export default function Index() {
    const [data, setData] = useState([])
    const navigate = useNavigate()
    const { axiosClientRef } = useContext(AxiosContext)

    const goToRepository = (repositoryId) => {
        navigate(`/repositories/${repositoryId}`);
    }

    useEffect(() => {
        axiosClientRef.current.get(`/repositories.json`).then(res => {
           setData(res.data);
        });
    }, [axiosClientRef])

    // Reduces the languages object to a single string with the primary language
    // being the language with the most lines of code
    const primaryLanguage = (languages) => {
        if (languages === null || languages.length === 0) {
            return null;
        }

        const primary = languages.reduce((primary, current) => {
            if (current.lines > primary.lines) {
                return current
            }
            return primary
        })

        return primary.language;
    }

    return (
        <>
            <header className="bg-white shadow">
                <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold tracking-tight text-gray-900">Repositories</h1>
                </div>
            </header>
            <main>
                <div className="container mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 bg-white my-5 shadow">
                    <table className="table w-full">
                        <thead>
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Name
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Visibility
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Language
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map(repository =>
                                <tr key={repository.id} className="cursor-pointer border p-6 rounded max-w-full my-5" onClick={() => goToRepository(repository.id)}>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-medium text-gray-900">
                                            {repository.name}
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm text-gray-500">
                                            {repository.visibility}
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm text-gray-500">
                                            {primaryLanguage(repository.languages)}
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </main>
        </>
    );
}
