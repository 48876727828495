import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AxiosContext} from "../Router";


export default function Index() {
    const [data, setData] = useState([])
    const navigate = useNavigate()
    const { axiosClientRef } = useContext(AxiosContext)

    const goToTeam = (teamId) => {
        navigate(`/teams/${teamId}`);
    }

    useEffect(() => {
        axiosClientRef.current.get(`/teams.json`).then(res => {
            setData(res.data);
        });
    }, [axiosClientRef])

    return (
        <>
            <header className="bg-white shadow">
                <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold tracking-tight text-gray-900">Teams</h1>
                </div>
            </header>
            <main>
                <div className="container mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 bg-white my-5 shadow">
                    {data.map(team =>
                        <div key={team.id} className="cursor-pointer border p-6 rounded max-w-full my-5" onClick={() => goToTeam(team.id)}>
                            <div className="font-bold">
                                {team.name}
                            </div>
                        </div>
                    )}
                </div>
            </main>
        </>
    );
}
