import {useContext, useEffect, useState} from "react";
import {Line} from "react-chartjs-2";
import {
    ArcElement,
    Chart as ChartJS,
    Colors,
    Legend,
    LinearScale, LineElement,
    PointElement, TimeScale, Title,
    Tooltip
} from "chart.js";
import {AxiosContext} from "../Router";
import 'chartjs-adapter-luxon';

const entityUrlSegment = {
    "repository": "repositories",
    "organization": "organizations",
    "team": "teams",
    "user": "users",
}

ChartJS.register(ArcElement, Title, LinearScale, TimeScale, PointElement, LineElement, Colors, Tooltip, Legend);

export default function PullRequestsByStatePerWeekChart({ entityType, entityId }) {
    const [data, setData] = useState([])
    const { axiosClientRef } = useContext(AxiosContext)

    useEffect(() => {
        axiosClientRef.current.get(`/${entityUrlSegment[entityType]}/${entityId}/pull_requests_by_state_per_week.json`).then(res => {
            setData(res.data);
        });
    }, [axiosClientRef, entityType, entityId]);

    const chartData = {
        datasets: [
            {
                label: "Merged",
                data: data.map(row => {
                    return {
                        x: row.week_start,
                        y: row.merged
                    }
                }),
                fill: false,
                backgroundColor: "rgb(54,194,103)",
                borderColor: "rgba(54,194,103, 0.4)",
            },
            {
                label: "Closed",
                data: data.map(row => {
                    return {
                        x: row.week_start,
                        y: row.closed
                    }
                }),
                fill: false,
                backgroundColor: "rgb(255, 99, 132)",
                borderColor: "rgba(255,99,132,0.4)",
            },
            {
                label: "Opened",
                data: data.map(row => {
                    return {
                        x: row.week_start,
                        y: row.opened
                    }
                }),
                fill: false,
                backgroundColor: "rgb(255, 205, 86)",
                borderColor: "rgba(255,205,86,0.4)",
            },
        ]
    }

    const chartOptions = {
        adapters: {
            date: {
                locale: 'en-GB'
            }
        },
        plugins: {
            legend: {
                position: 'bottom',
            }
        },
        responsive: true,
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'week',
                    displayFormats: {
                        week: 'd MMM'
                    },
                    tooltipFormat: 'd MMM'
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: '# of Pull Requests',
                },
            },
        }
    }

    return (<Line data={chartData} options={chartOptions} />);
}
